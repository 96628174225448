import { lazy } from 'react';

import { useModal } from '~/components/modal';

const ShareApplicationModal = lazy(() => import('~/features/share/modals/ShareApplicationModal'));

function useShareApplication() {
  const { open } = useModal(ShareApplicationModal);
  return open;
}

export { useShareApplication };
