import { lazy } from 'react';

import { useModal } from '~/components/modal';

const UploadAssetModal = lazy(() => import('~/features/global/modals/UploadAssetModal'));

function useUploadAsset() {
  const { open } = useModal(UploadAssetModal);
  return open;
}

export { useUploadAsset };
