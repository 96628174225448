import { useMemo } from 'react';

import { useContactSales } from './useContactSales';
import { useContactUs } from './useContactUs';
import { useCreateRecording } from './useCreateRecording';
import { useSwitchAccount } from './useSwitchAccount';
import { useUploadAsset } from './useUploadAsset';
import { useUploadVideo } from './useUploadVideo';

function useGlobalActions() {
  const switchAccount = useSwitchAccount();
  const uploadAsset = useUploadAsset();
  const uploadVideo = useUploadVideo();
  const createRecording = useCreateRecording();
  const contactUs = useContactUs();
  const contactSales = useContactSales();

  return useMemo(
    () => ({
      switchAccount,
      uploadAsset,
      uploadVideo,
      createRecording,
      contactUs,
      contactSales
    }),
    [switchAccount, uploadAsset, uploadVideo, createRecording, contactUs, contactSales]
  );
}

export { useGlobalActions };
