import { useMemo } from 'react';

import { useAddFromLibrary } from './useAddFromLibrary';
import { useAddToPlaylist } from './useAddToPlaylist';
import { useBulkDeletePlaylists } from './useBulkDeletePlaylists';
import { useBulkDuplicatePlaylists } from './useBulkDuplicatePlaylists';
import { useBulkMovePlaylists } from './useBulkMovePlaylists';
import { useCreatePlaylist } from './useCreatePlaylist';
import { useDeletePlaylist } from './useDeletePlaylist';
import { useDuplicatePlaylist } from './useDuplicatePlaylist';
import { useGetShareItems } from './useGetShareItems';
import { usePlayPlaylist } from './usePlayPlaylist';
import { useRenamePlaylist } from './useRenamePlaylist';
import { useUploadVideo } from './useUploadVideo';

function usePlaylistActions() {
  const createPlaylist = useCreatePlaylist();
  const addFromLibrary = useAddFromLibrary();
  const uploadVideo = useUploadVideo();
  const renamePlaylist = useRenamePlaylist();
  const playPlaylist = usePlayPlaylist();
  const deletePlaylist = useDeletePlaylist();
  const duplicatePlaylist = useDuplicatePlaylist();
  const getShareItems = useGetShareItems();
  const addToPlaylist = useAddToPlaylist();
  const bulkMovePlaylists = useBulkMovePlaylists();
  const bulkDuplicatePlaylists = useBulkDuplicatePlaylists();
  const bulkDeletePlaylists = useBulkDeletePlaylists();

  return useMemo(
    () => ({
      createPlaylist,
      addFromLibrary,
      uploadVideo,
      renamePlaylist,
      playPlaylist,
      deletePlaylist,
      duplicatePlaylist,
      getShareItems,
      addToPlaylist,
      bulkMovePlaylists,
      bulkDuplicatePlaylists,
      bulkDeletePlaylists
    }),
    [
      createPlaylist,
      addFromLibrary,
      uploadVideo,
      renamePlaylist,
      playPlaylist,
      deletePlaylist,
      duplicatePlaylist,
      getShareItems,
      addToPlaylist,
      bulkMovePlaylists,
      bulkDuplicatePlaylists,
      bulkDeletePlaylists
    ]
  );
}

export { usePlaylistActions };
