import { useMemo } from 'react';

import { useShareApplication } from '~/features/share/actions/useShareApplication';
import { useShareEmailEmbed } from '~/features/share/actions/useShareEmailEmbed';
import { useShareEmbedCode } from '~/features/share/actions/useShareEmbedCode';
import { useShareQRCode } from '~/features/share/actions/useShareQRCode';

function useShareActions() {
  const shareQRCode = useShareQRCode();
  const shareEmbedCode = useShareEmbedCode();
  const shareEmailEmbed = useShareEmailEmbed();
  const shareApplication = useShareApplication();

  return useMemo(
    () => ({
      shareQRCode,
      shareEmbedCode,
      shareEmailEmbed,
      shareApplication
    }),
    [shareQRCode, shareEmbedCode, shareEmailEmbed, shareApplication]
  );
}

export { useShareActions };
